html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

body {
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  background-color: #1a202c;
}

img {
  width: 100%;
}

.type-h1 {
  color: #4fd1c5;
  font-weight: 300;
  font-size: 95px;
  letter-spacing: -1.25px;
  line-height: 1.5;
}
.type-h2 {
  color: #ffffff;
  font-weight: 300;
  font-size: 59px;
  letter-spacing: -0.5px;
  line-height: 1.5;
}
.type-h3 {
  color: #ffffff;
  font-weight: 400;
  font-size: 48px;
  letter-spacing: 0px;
  line-height: 1.5;
}
.type-h4 {
  color: #ffffff;
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.25px;
  line-height: 1.5;
}
.type-h5 {
  color: #ffffff;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.25px;
  line-height: 1.5;
}
.type-b1 {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}
.type-btn {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 1.5;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  max-width: 1024px;
  padding: 1rem;
  margin: 7rem auto;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #1a202c;
  color: #ffffff;
}
.navbar__item {
  color: #ffffff;
  height: 100%;
  margin-right: 2rem;
  transition: all ease-in 100ms;
}
.navbar__item:hover {
  transform: translate(0px, -2px);
}
.navbar__btn {
  background-color: #4fd1c5;
  color: #ffffff;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.25rem;
}
.navbar__logo {
  color: #ffffff;
}
.navbar__logo--last {
  margin-right: 3rem;
}

.hero {
  width: 100%;
}
.hero__icon {
  margin-right: 1.5rem;
}
.hero__title {
  margin-bottom: 1rem;
}
.hero__description {
  margin-bottom: 4rem;
}
.hero__cursor {
  background-color: #4fd1c5;
  display: inline-block;
  width: 30px;
  height: 90px;
  margin-left: 0.5rem;
  transition: all ease-in 150ms;
}

.project-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.project-section__title {
  margin-bottom: 2rem;
}

.project-card {
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
}
.project-card__title {
  margin-bottom: 1.5rem;
}
.project-card__description {
  margin-bottom: 2rem;
}

.tech-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.tech-list__item {
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  border-color: #ffffff;
  border-style: solid;
  border-width: 0.125rem;
  border-radius: 0.25rem;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.btn-group__btn {
  padding: 1rem 2rem;
  background-color: #4fd1c5;
  color: #ffffff;
  border-radius: 0.25rem;
  margin-right: 1rem;
  transition: all ease-in 100ms;
}
.btn-group__btn:hover {
  transform: translate(0px, -2px);
}

.social-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 640px) {
  .navbar__item--hide-sm {
    display: none;
  }

  .btn-group__icon {
    display: none;
  }

  .tech-list {
    display: none;
  }

  .hero__description {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hero__cursor {
    display: none;
  }
  .hero__icon {
    display: none;
  }

  .btn-group__icon {
    display: none;
  }
}
@media screen and (min-width: 640px) {
  .social-list {
    display: none;
  }
}
/*# sourceMappingURL=index.79320895.css.map */
