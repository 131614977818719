$background: #1a202c;
$onBackground: #ffffff;
$surface: #2d3748;
$primary: #4fd1c5;
$onPrimary: #ffffff;
$secondary: #f6ad55;
$onSecondary: #000000;
$black: #000000;
$white: #ffffff;

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
}

body {
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  background-color: $background;
}

img {
  width: 100%;
}

.type {
  &-h1 {
    color: $primary;
    font-weight: 300;
    font-size: 95px;
    letter-spacing: -1.25px;
    line-height: 1.5;
  }
  &-h2 {
    color: $onBackground;
    font-weight: 300;
    font-size: 59px;
    letter-spacing: -0.5px;
    line-height: 1.5;
  }
  &-h3 {
    color: $onBackground;
    font-weight: 400;
    font-size: 48px;
    letter-spacing: 0px;
    line-height: 1.5;
  }
  &-h4 {
    color: $onBackground;
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.25px;
    line-height: 1.5;
  }
  &-h5 {
    color: $onBackground;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.25px;
    line-height: 1.5;
  }
  &-b1 {
    color: $onBackground;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }
  &-btn {
    color: $onBackground;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    line-height: 1.5;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  max-width: 1024px;
  padding: 1rem;
  margin: 7rem auto;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $background;

  color: $onBackground;
  &__item {
    color: $onBackground;
    height: 100%;
    margin-right: 2rem;
    transition: all ease-in 100ms;
    &:hover {
      transform: translate(0px, -2px);
    }
  }
  &__btn {
    background-color: $primary;
    color: $onPrimary;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.25rem;
  }
  &__logo {
    color: $onBackground;
    &--last {
      margin-right: 3rem;
    }
  }
}

.hero {
  width: 100%;
  &__icon {
    margin-right: 1.5rem;
  }
  &__title {
    margin-bottom: 1rem;
  }
  &__description {
    margin-bottom: 4rem;
  }
  &__cursor {
    background-color: $primary;
    display: inline-block;
    width: 30px;
    height: 90px;
    margin-left: 0.5rem;
    transition: all ease-in 150ms;
  }
}

.project-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  &__title {
    margin-bottom: 2rem;
  }
}

.project-card {
  background-color: $surface;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
  &__title {
    margin-bottom: 1.5rem;
  }
  &__description {
    margin-bottom: 2rem;
  }
}

.tech-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  &__item {
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    border-color: $onBackground;
    border-style: solid;
    border-width: 0.125rem;
    border-radius: 0.25rem;
  }
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &__btn {
    padding: 1rem 2rem;
    background-color: $primary;
    color: $onPrimary;
    border-radius: 0.25rem;
    margin-right: 1rem;
    transition: all ease-in 100ms;
    &:hover {
      transform: translate(0px, -2px);
    }
  }
}

.social-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

// less than 640
@media screen and (max-width: 640px) {
  .navbar {
    &__item {
      &--hide-sm {
        display: none;
      }
    }
  }
  // .container {
  //   margin-top: 1rem;
  // }
  .btn-group {
    &__icon {
      display: none;
    }
  }
  .tech-list {
    display: none;
  }
  .hero {
    &__description {
      display: none;
    }
  }
}

// less than 1024
@media screen and (max-width: 1024px) {
  .hero {
    &__cursor {
      display: none;
    }
    &__icon {
      display: none;
    }
  }
  .btn-group {
    &__icon {
      display: none;
    }
  }
}

//more than 640
@media screen and (min-width: 640px) {
  .social-list {
    display: none;
  }
}
